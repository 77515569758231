import SentryNextJS from "@dreambigger/shared/src/utils/sentry/nextjs";
import { SentryConfig } from "../config/sentry";

const sentry = new SentryNextJS();

/**
 * Initialize Sentry for the Acquire Flows application.
 */
export const initializeSentry = () => {
  return sentry.init(SentryConfig);
};
