import { NextjsOptions } from "@sentry/nextjs/types/utils/nextjsOptions";

const SENTRY_DSN =
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  "https://cf7b7875496e42cb745179062ffc0449@o1254013.ingest.sentry.io/4506622229020672";

const SENTRY_ENV = process.env.NEXT_PUBLIC_SENTRY_ENV || "development";

/**
 * Sentry Configuration for Acquire Flows
 */
export const SentryConfig: NextjsOptions = {
  environment: SENTRY_ENV,
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
};
