import * as Sentry from "@sentry/nextjs";
import { NextjsOptions } from "@sentry/nextjs/types/utils/nextjsOptions";

/**
 * Helper class to initialize Sentry in a Next.js application and
 * configure whatever options the specific application needs.
 *
 * REFERENCE: https://docs.sentry.io/platforms/javascript/guides/nextjs/
 */
class SentryNextJS {
  /**
   * REFERENCE: https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/options/
   *
   * @param {NextjsOptions} options - Options used to configure Sentry's SDK
   */
  init(options: NextjsOptions) {
    return Sentry.init(options);
  }
}

export default SentryNextJS;
